/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Produkty značky Master Magic"}>
        <Column className="css-wna3i2 --full --parallax pb--60 pt--60" name={"sb5u9kyq71"} parallax={true} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/e556415b9cfb4d2bb60385ecc0b57ebb_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/e556415b9cfb4d2bb60385ecc0b57ebb_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/e556415b9cfb4d2bb60385ecc0b57ebb_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/e556415b9cfb4d2bb60385ecc0b57ebb_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/e556415b9cfb4d2bb60385ecc0b57ebb_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/e556415b9cfb4d2bb60385ecc0b57ebb_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/e556415b9cfb4d2bb60385ecc0b57ebb_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/e556415b9cfb4d2bb60385ecc0b57ebb_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box title-box--shadow2 fs--36 title-box--invert" content={"<span style=\"font-weight: bold;\"><br><span style=\"color: var(--color-blend--85);\">&nbsp;\"XXL návin, XS cena – luxusní pohodlí, které vaší peněžence rozumí.\"</span></span><span style=\"color: var(--white)\"><br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"q2o8ks58oe"} layout={"l12"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/35535/d63218f38bfd4244b37dacbf7bbf3bcd_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/35535/d63218f38bfd4244b37dacbf7bbf3bcd_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/d63218f38bfd4244b37dacbf7bbf3bcd_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/d63218f38bfd4244b37dacbf7bbf3bcd_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/d63218f38bfd4244b37dacbf7bbf3bcd_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--60 pt--60" name={"x5cb1y31rkb"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/aa5c85a8e74b4f238827de1032fd7f17_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/35535/aa5c85a8e74b4f238827de1032fd7f17_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/aa5c85a8e74b4f238827de1032fd7f17_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/aa5c85a8e74b4f238827de1032fd7f17_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/aa5c85a8e74b4f238827de1032fd7f17_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/c923266d22f34ee2b84d585821162c30_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/35535/c923266d22f34ee2b84d585821162c30_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/35535/c923266d22f34ee2b84d585821162c30_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/35535/c923266d22f34ee2b84d585821162c30_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/35535/c923266d22f34ee2b84d585821162c30_s=1400x_.jpeg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"bc553peg8k4"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/obecne/5a0eecacd2424abf81c900821b205807.svg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":80}} srcSet={""} svgColor={"rgba(235,45,45,1)"}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: var(--black);\">Znáte ten pocit, když investujete do něčeho, co na první pohled vypadá jako luxus, ale pak zjistíte, že kvalita zaostává? S Master Magic se vám to nikdy nestane. Garantujeme vám, že každý cent investovaný do našich produktů se promění v nekonečné okamžiky obdivu a uznání od těch, pro koho tvoříte.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/obecne/5a0eecacd2424abf81c900821b205807.svg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":80}} srcSet={""} svgColor={"rgba(235,45,45,1)"}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: var(--black);\">Nechte své obavy z vysoké ceny za kvalitní papírové výrobky za sebou. Master Magic vám nabízí to nejlepší z obou světů – nepřekonatelnou kvalitu, která přesahuje očekávání, a přitom za cenu, která je spravedlivá. Už žádné kompromisy, jen čistá dokonalost v každém detailu.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}